<template>
  <template v-if="dbSynced">
    <router-view />
  </template>
</template>

<script>
import axios from "axios";

import initialLoad from "@/viewController/App";
import validate from "@/general/helperMethods/validate";

import db_auth from "@/db/controller/auth";

export default {
  data: () => {
    return {
      axiosInstance: null,
    };
  },
  async created() {
    const uuid = validate.getParameterByName("uuid");
    const user_id = validate.getParameterByName("user_id");
    this.gp_url = validate.getParameterByName("gp_url");
    if (uuid && user_id) {
      await this.createAxiosInstance();
      const jwtToken = await this.LoginViaApi(uuid, user_id);
      const user = await db_auth.authenticateWithFirebase(jwtToken);
    }

    if (window.location.href.split("/")[3] == "login") {
      this.dbSynced = true;
      return;
    } else if (window.location.href.split("/")[3] == "announcement") {
      if (this.store.b2b.uid == null || this.store.b2b.uid == "") {
        this.store.b2b.uid = window.location.href.split("/")[6];
      }
    } else if (window.location.href.split("/")[3]) {
      this.store.b2b.uid = window.location.href.split("/")[3];
    }

    await initialLoad.loadDataFromDBInStateManager(this.store.b2b.uid);
    await initialLoad.setAuth();
    this.dbSynced = true;
  },
  methods: {
    async createAxiosInstance() {
      this.axiosInstance = await axios.create({
        baseURL: "https://testapiv2.themozo.net",
      });
    },
    async LoginViaApi(uuid, user_id) {
      return await this.axiosInstance
        .post("/v2/mini/get-session", {
          session_id: uuid,
        })
        .then((response) => {
          if (
            response == null &&
            response.data == null &&
            response.data.token == null
          )
            throw Error(
              "You didnt receive the correct information please try again!"
            );
          return response.data.token;
        })
        .catch((er) => {
          console.log(er);
          return null;
        });
    },
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    dbSynced: {
      get() {
        return this.store.environment.dbSynced;
      },
      set(value) {
        this.store.environment.dbSynced = value;
      },
    },
    switchOption: {
      get() {
        return this.store.environment.switchOption;
      },
      set(value) {
        this.store.environment.switchOption = value;
      },
    },
    gp_url: {
      get() {
        return this.store.environment.gp_url;
      },
      set(value) {
        this.store.environment.gp_url = value;
      },
    },
  },
};
</script>

<style></style>
