import db_announcement from "@/db/controller/announcement"
import db_category from "@/db/controller/category"

import db_auth from "@/db/controller/auth"

import store from "@/store/index"
import GenericObject from "@/general/objects/General/GenericObject"
import { any } from "io-ts"
import b2b from "@/store/data/b2b"
import axios from 'axios'; // 

export default {
    async loadDataFromDBInStateManager(b2b_uid: string) {
        store.state.announcements.events = await db_announcement.getAll(
            b2b_uid,
            "events"
        )

        store.state.announcements.news = await db_announcement.getAll(
            b2b_uid,
            "news"
        )
        store.state.announcements.categories = await db_category.getAll(b2b_uid)

        const info = await db_announcement.getInfo(b2b_uid)
        const id = info!.guestpage_id
        const url = `https://testapiv2.themozo.net/v2/guestpage/guest-page/${id}`;
        const headers = {
            'Content-Type': 'application/json',
        };

        const response = await axios.get(url, { headers });
        const guestpage_info = response.data
        store.state.b2b.colors = info!.colors
        store.state.b2b.colors.background = guestpage_info!.custom_colors.colors_general
        store.state.b2b.backlink = info!.backlink
        store.state.b2b.logo = guestpage_info!.logo
        store.state.b2b.admins = info!.admins
        store.state.b2b.invert = info!.invert

        store.state.b2b.name = info!.name
        store.state.b2b.tenant_name = info!.tenant_name

        let faviconLink: any = document.getElementById('favicon');
        let maskIconLink: any = document.querySelector('link[rel="mask-icon"]');
        let webclipLink: any = document.getElementById('webclip');

        if (faviconLink) {
            faviconLink.href = store.state.b2b.logo
        } else {
            const newFaviconLink: any = document.createElement('link');
            newFaviconLink.href = store.state.b2b.logo + '?v=' + Date.now()
            newFaviconLink.rel = 'shortcut icon';
            newFaviconLink.type = 'image/x-icon';
            newFaviconLink.id = 'favicon';
            document.head.appendChild(newFaviconLink);
        }


        if (webclipLink) {
            webclipLink.href = store.state.b2b.logo
        } else {
            const newWebclipLink: any = document.createElement('link');
            newWebclipLink.href = store.state.b2b.logo + '?v=' + Date.now()
            newWebclipLink.rel = 'apple-touch-icon';
            newWebclipLink.id = 'webclip';
            document.head.appendChild(newWebclipLink);
        }


        if (maskIconLink) {
            maskIconLink.href = store.state.b2b.logo
        } else {
            maskIconLink = document.createElement('link');
            maskIconLink.href = store.state.b2b.logo + '?v=' + Date.now()
            maskIconLink.rel = 'mask-icon';
            maskIconLink.color = '#5bbad5'; // Replace with your desired mask-icon color
            document.head.appendChild(maskIconLink);
        }

        // Create the title element
        const titleElement = document.createElement('title');
        titleElement.textContent = store.state.b2b.name; // Replace 'Your Desired Title' with your desired title text

        document.head.appendChild(titleElement);





    },

    async setAuth() {
        const user: any = await db_auth.userState().then((user) => {
            return user
        })

        if (user != null && store.state.b2b.admins.includes(user.uid)) {
            store.state.environment.isConfig = true
        }
        // else if (user==null){
        //     window.open("https://themozo.app/"+store.state.b2b.tenant_name, "_self")
        //  } 
        else {
            store.state.environment.isConfig = false
        }
    },
}
