<template>
  <div class="header-column-tm margin-top">
    <div>
      <template v-if="back">
        <div @click="isClicked">
          <Icon
            :icon="'arrowback'"
            :size="'40px'"
            :color1="store.b2b.colors.font[0]"
            :color2="store.b2b.colors.font[0]"
            :extra_css="'padding:0px; cursor:pointer;'"
          />
        </div>
      </template>
      <template v-else-if="apps">
        <div class="settings-div">
          <div @click="showAllApps" class="link-block w-inline-block">
            <Icon
              :icon="'arrowback'"
              :size="'40px'"
              :color1="store.b2b.colors.font[0]"
              :color2="store.b2b.colors.font[0]"
              :extra_css="'padding:0px; cursor:pointer;'"
            />
          </div>
        </div>
      </template>
    </div>
    <div class="logo-header">
      <img :src="logo" loading="lazy" class="mozo-logo" />
    </div>
    <div class="header-div">
      <img
        v-if="store.environment.isConfig && !showOldMessages && showBackup"
        @click="changeMessageState(true)"
        src="/images/folder.svg"
        loading="lazy"
        class="back-button"
      />
      <img
        v-if="store.environment.isConfig && showOldMessages && showBackup"
        @click="changeMessageState(false)"
        src="/images/new.svg"
        loading="lazy"
        class="back-button"
      />
    </div>
  </div>
</template>

<script>
import Icon from "@/general/components/General/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: ["back", "apps", "showBackup"],
  data: () => {
    return {
      logo: "/images/mozo_logo_nopadding-01.svg",
      back_checked: "",
      showOldMessages: false,
    };
  },
  created() {
    if (this.inputLogo != null) {
      this.logo = this.inputLogo;
    }
  },
  methods: {
    changeMessageState(value) {
      if (value == null) {
        throw Error("changeMessageState(null)");
      }
      this.showOldMessages = value;
      this.$emit("changeMessageState", value);
    },
    isClicked() {
      this.$router.back();
    },
    showAllApps() {
      if (this.gp_url == null) {
        this.$router.back();
      } else {
        window.open(
          process.env.VUE_APP_GUESTPAGE_DOMAIN + "/" + this.gp_url,
          "_self"
        );
      }
    },
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    backlink: function () {
      return this.$store.state.b2b.backlink;
    },
    inputLogo: function () {
      return this.$store.state.b2b.logo;
    },
    invert: function () {
      return this.store.b2b.invert ? 1 : 0;
    },
    gp_url: function () {
      return this.store.environment.gp_url;
    },
  },
};
</script>

<style scoped>
.header-column-tm.margin-top {
  margin-top: 0%;
  margin-bottom: 0%;
  max-width: 650px;
  width: 100%;
  height: auto;
  padding: 2% 0%;
}

@media screen and (max-width: 650px) {
  .header-column-tm.margin-top {
    padding: 2% 5%;
  }
}

.logo-header {
  justify-content: center;
}

.mozo-logo {
  border-radius: 100%;
  border-style: solid;
  border-color: #f4f4f4;
  border-width: 0px;

  width: 75px;
  height: 75px;
}

.settings-div {
  width: auto;
  height: auto;
}
</style>
